@import "variables";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "legacy/company";
@import "legacy/gtcookies";

.gt-bootstrap {
    @import "~bootstrap/scss/bootstrap.scss";
}