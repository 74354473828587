/* Overlay */
#gtcookies-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 2147483647;
}

.gtcookies-change-settings {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    padding: 5px;
    text-align: center;
    line-height: 50px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    cursor: pointer;
}

/* Modal */
#gtcookies-modal-container {
    position: fixed;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483648;

    p {
        margin-bottom: 0;
        overflow: hidden;
    }
}

#gtcookies-modal {
    @extend %transition;

    &.open {
        @extend .container;

        .gtcookies-info,
        .gtcookies-actions {
            display: none;
        }
    }
}

#gtcookies-modal,
#header #gtcookies-modal.row,
#gtcookies-modal-not-available {
    width: 40vw;
    display: none;
    text-align: justify;
    font-size: 16px;
    position: fixed !important;
    padding: 0 !important;
    margin: 0 auto;
    background-color: #fff;
    color: #000;
    border-radius: $border-radius;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width : 991px) {
        width: calc(100% - 30px);
    }

    &.gtcookies-top {
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
    }
    &.gtcookies-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &.gtcookies-bottom {
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }
    &.gtcookies-bottom-left {
        bottom: 0;
        left: 0;
        float: left;
    }
    &.gtcookies-bottom-right {
        bottom: 0;
        left: 0;
        float: right;
    }

    .gtcookies-close {
        position: absolute;
        top: 0;
        right: 15px;
        color: #000;
        opacity: 1;
        font-size: 2rem;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 1;
        padding: 0;
    }

    .logo {
        max-height: 100px;
    }

    /* Actions */
    .gtcookies-btn {
        margin: 6px 0;
        padding: 8px 14px;
        border: 1px solid transparent;
        cursor: pointer;
        clear: both;

        @media (max-width: 575.98px) {
            width: 100%;
        }
    }
    .gtcookies-save {
        display: none;

        &:disabled {
            cursor: not-allowed;
        }
    }
    .gtcookies-save-disclaimer {
        font-size: small;
        font-style: italic;
    }
    .coookiesplus-policy {
        text-align: right;
        clear: both;
    }
    .gtcookies-info {
        padding: 30px 45px !important;
        max-height: 30vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 15px;

        @media (max-width : 991px) {
            max-height: 70vh;
        }
    }
    .gtcookies-finalities {
        margin-bottom: 0px;
        display: none;
    }
    .gtcookies-actions {
        padding: 30px 45px !important;
    }
    .nav-cookie,
    .btn-cookie-validate {
        padding: 30px 30px 30px 45px !important;

        @media (max-width : 991px) {
            padding: 20px 30px !important;
        }
    }
    .tab-content {
        padding: 30px 45px 30px 30px !important; 
    }
    .btn-cookie-validate {
        border-top: darken(#e2e2e2, 8%) solid 1px;
    }
} 


/* Switch */
.gtcookies-finality-container {
    cursor: pointer;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.gtcookies-finality-switch-container {
    display: inline-block;

    label {
        line-height: 24px;
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

        &[disabled="disabled"] {
            cursor: not-allowed;
        }
    }
}


.gtcookies-finality-switch {
    display: inline-block;
    border-radius: 24px;
    height: 24px;
    width: 50px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    margin: 0 6px;

    &::before,
    &::after {
        content: "";
    }
}


.gtcookies-finality-checkbox.disabled ~ .gtcookies-finality-switch,
.gtcookies-finality-checkbox:disabled ~ .gtcookies-finality-switch {
    cursor: not-allowed;
    opacity: 0.65;
}

.gtcookies-finality-switch:before {
    display: block;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    transition: left 0.25s;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
}

.gtcookies-finality-container:hover .gtcookies-finality-switch:before {
    background: #fff;
}

.gtcookies-finality-checkbox[value="na"]:checked ~ .gtcookies-finality-switch {
    background: #CCCCCC;
}

.gtcookies-finality-checkbox[value="na"]:checked ~ .gtcookies-finality-switch:before {
    left: 16px;
}

.gtcookies-finality-checkbox[value="off"]:checked ~ .gtcookies-finality-switch {
    background: #ff0f0f;
}

.gtcookies-finality-checkbox[value="off"]:checked ~ .gtcookies-finality-switch:before {
    content: '✘';
    color: #FF0F0F;
}

.gtcookies-finality-checkbox[value="on"]:checked ~ .gtcookies-finality-switch {
    background: #56c080;
}

.gtcookies-finality-checkbox[value="on"]:checked ~ .gtcookies-finality-switch:before {
    left: 27px;
    content: '✔';
    color: #56c080;
}

.gtcookies-finality-checkbox {
    position: absolute;
    visibility: hidden;
}

.gtcookies-finality-switch:focus,
.gtcookies-finality-switch.focus,
.gtcookies-finality-switch:focus.active,
.gtcookies-finality-switch.focus.active {
    outline: none;
}

#gtcookies-finalities-container {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width : 991px) {
        max-height: 70vh;

    }
}

.cookieplus-finality-container {
    border: 1px solid #CCC;
    margin: 8px 0;
    padding: 8px 0;
    border-radius: 5px;
}

.cookieplus-finality-container:first-child {
    margin-top: 0;
}

.cookieplus-finality-container:last-child {
    margin-bottom: 8px;
}

#gtcookies-modal table {
    border-collapse: collapse;
    width: 100%;
    margin: 14px 0 8px;
}

#gtcookies-modal td,
#gtcookies-modal th {
    border: 1px solid #CCCCCC;
    padding: 4px 8px;
    text-align: left;
}

#gtcookies-modal th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #FAFAFA;
}

#gtcookies-modal.col-lg-4 table thead {
    display: none;
}

#gtcookies-modal.col-lg-4 table tr {
    width: 100%;
    margin-bottom: 20px;
    float: left;
}

#gtcookies-modal.col-lg-4 table td {
    display: flex;
    padding: 0;
    border-bottom: 0;
    word-break: break-word;
}

#gtcookies-modal.col-lg-4 table td:last-child {
    display: flex;
    border-bottom: 1px solid #CCCCCC;
}

#gtcookies-modal.col-lg-4 table td::before {
    content: attr(label);
    font-weight: bold;
    width: 120px;
    min-width: 120px;
    border-right: 1px solid #CCCCCC;
    padding: 4px 8px;
}

#gtcookies-modal.col-lg-4 table td span {
    padding: 4px 8px;
}

/* Responsive tables */
@media screen and (max-width: 767px) {
    #gtcookies-modal table thead {
        display: none;
    }

    #gtcookies-modal table tr {
        width: 100%;
        margin-bottom: 20px;
        float: left;
    }

    #gtcookies-modal table td {
        display: flex;
        padding: 0;
        border-bottom: 0;
    }

    #gtcookies-modal table td:last-child {
        display: flex;
        border-bottom: 1px solid #CCCCCC;
    }

    #gtcookies-modal table td::before {
        content: attr(label);
        font-weight: bold;
        width: 120px;
        min-width: 120px;
        border-right: 1px solid #CCCCCC;
        padding: 4px 8px;
    }

    #gtcookies-modal table td span {
        padding: 4px 8px;
    }
}

#gtcookies-modal-container .material-icons {
    vertical-align: middle;
}

#gtcookie-tab {
    border: none !important;

    li, a {
        display: block;
        width: 100%;
        color: #000;
    }
    li {
        border: none !important;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        a {
            border: darken(#e2e2e2, 8%) solid 1px;
            border-radius: $border-radius / 2;
            padding: 15px;

            &.active {
                background: #e2e2e2;
                font-weight: bold;
            }
        }
    }
}